@import 'src/styles/variables';

.list {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -20px;

  @include for-size(phone-only) {
    display: block;
    margin: 0;
  }
}

.item {
  width: 300px;
  flex-basis: 33%;
  font-weight: 300;
  box-sizing: border-box;

  @include for-size(phone-only) {
    width: 100%;
    padding: 0 0 30px 0;
  }
}

.itemChild {
  padding: 30px 20px;
  @include for-size(phone-only) {
    padding: 0;
  }
}

.headline {
  color: $darkblue;
  line-height: 1.5;
  margin-bottom: 10px;

  @include for-size(phone-only) {
    font-size: 18px;
  }
}

.content {
  line-height: 1.75;
}

.icon {
  background-color: $white-shadow;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  margin-bottom: 20px;
  line-height: 0;

  div {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }
}

.smallIcon {
  // background-color: $white-shadow;
  width: 100px;
  height: 100px;
  line-height: 0;

  box-sizing: border-box;
  border-radius: 50%;
  margin-bottom: 20px;
  padding: 20px;
}

.smallerIcon {
  background-color: $white-shadow;
  width: 50px;
  height: 50px;
  line-height: 0;

  box-sizing: border-box;
  border-radius: 50%;
  margin-bottom: 20px;
}

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 20px;
  width: calc(33.33% - 80px);
  background: $white;
  border-radius: 5px;
  text-decoration: none;
  // transition: all 0.6s $cubic;
  padding: 20px;
  flex-grow: 1;

  @include for-size(tablet-landscape-down) {
    width: calc(50% - 80px);
    max-width: 480px;
    min-height: 118px;
  }

  @include for-size(phone-only) {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0px;
    min-height: 170px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
    pointer-events: none;
    // box-shadow: $basic-shadow;
    box-shadow: $shadow-off;
    transition: 0.2s;
    mix-blend-mode: multiply;
    z-index: $z-background;
  }

  &:hover:before {
    box-shadow: $shadow-on;
  }

  &:hover > .action {
    transform: translateY(-2px);
  }
}

.action {
  padding-top: 10px;
  margin-top: auto;
  transition: transform 0.2s $cubic;
}
